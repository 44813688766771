import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import * as dd from 'dingtalk-jsapi';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '证照借阅'
    },
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: '证照借阅'
    },
    component: Home
  },
  {
    path: '/lisenceList',
    name: 'LisenceList',
    component: () => import(/* webpackChunkName: "about" */ '../views/LisenceList.vue'),
  },
  {
    path: '/borrow',
    name: 'Borrow',
    component: () => import(/* webpackChunkName: "about" */ '../views/Borrow.vue'),
    meta: {
      title: '借阅工作台'
    }
  },
  {
    path: '/delay',
    name: 'Delay',
    meta: {
      title: '申请延期'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Delay.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: {
      title: '详情'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Detail.vue')
  },
  {
    path: '/borrowingDesk',
    name: 'BorrowingDesk',
    meta: {
      title: '借阅工作台'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/BorrowingDesk.vue')
  },
  {
    path: '/licenseReturn',
    name: 'LicenseReturn',
    meta: {
      title: '证照归还'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/LicenseReturn.vue')
  },
  {
    path: '/my',
    name: 'My',
    meta: {
      title: '我的'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/My2.vue')
  },
  {
    path: '/word',
    name: 'Word',
    meta: {
      title: '操作指南'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Word.vue')
  }
]

const router = new VueRouter({
  routes
})
// 守卫路由  
router.beforeEach(async function (to, from, next) {
  // if (to.meta.title=='申请借阅') { document.title = to.meta.title } 
  if (to.meta.title) {
    dd.biz.navigation.setTitle({
      title: to.meta.title,
      onSuccess: function (res) {
        console.log(res)
      },
      onFail: function (err) {
        console.log(err)
      }
    })
  }
  next()
})

export default router
