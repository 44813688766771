let dealUrlParams = (obj) => {
  let params = new URLSearchParams()
  for (let i in obj) {
    if (obj[i] !== '' && obj[i] !== undefined && obj[i] !== null) params.append(i, obj[i])
  }
  return params
}

function formatter(time) {
  let nowdata = time == null ? new Date() : new Date(time)
  let year = nowdata.getFullYear()
  let month = nowdata.getMonth() + 1
  let date = nowdata.getDate()
  let nowtime = year + '-'
  if (month < 10) {
    nowtime += '0'
  }
  nowtime += month + '-'
  if (date < 10) {
    nowtime += '0'
  }
  nowtime += date

  return nowtime
}

function addDay(date, dayNumber) {

  date = date ? date : new Date();

  var ms = dayNumber * (1000 * 60 * 60 * 24)

  var newDate = new Date(date.getTime() + ms);

  return newDate;

}


export { dealUrlParams, formatter, addDay }
