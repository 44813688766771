import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
Vue.use(_Tabbar);
Vue.use(_TabbarItem);
Vue.use(_Toast);
export default {
  data: function data() {
    return {
      active: 0,
      isTabBarDisabled: this.$store.state.isTabBarDisabled
    };
  },
  methods: {
    onChange: function onChange(index) {
      if (this.isTabBarDisabled) {
        if (index === 0) {
          this.$router.push({
            name: 'Home'
          });
        } else if (index === 1) {
          this.$router.push('/my');
        }
      }
    }
  }
};