import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from "./store";
import './assets/css/common.less'
import VConsole from 'vconsole'
  const vConsole = new VConsole()
  Vue.use(vConsole)
Vue.use(Vuex)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
