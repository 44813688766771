import axios from 'axios'
import { Notify, Toast } from 'vant'
import store from '../store/index';
const service = axios.create({
  // baseURL: 'http://10.104.5.211:8082',
  // baseURL: 'https://aics-im-test.pec.com.cn',
  // timeout: 60000
})
let loading
function startLoading() {
  loading = Toast.loading({
    message: '加载中...',
    forbidClick: true,
  })
}

function endLoading() {
  loading.clear()
}
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    store.commit('changeIsTabBarDisabled', false)
    startLoading();
    return config
  },
  (error) => {
    store.commit('changeIsTabBarDisabled', true)
    endLoading();
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    let { code, message } = response.data
    if (code == 200) {
      // Notify({ type: 'success', message: message });
    } else if (code == 10120) {
      Notify({ type: 'danger', message: message })
    } else {
      Notify({ type: 'warning', message: message })
    }
    store.commit('changeIsTabBarDisabled', true)
    endLoading();
    return response
  },
  (error) => {
    Notify({ type: 'warning', message: `异常：${error.data}` });
    store.commit('changeIsTabBarDisabled', true)
    endLoading();
    return Promise.reject(error)
  }
)

export default service
