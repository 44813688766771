import service from '../utils/request'

//钉钉端获取用户分页借阅列表
export function getLicenseList(data) {
  return service({
    method: 'post',
    url: '/license/getLicenseList',
    data
  })
}

//钉钉端获取用户分页借阅列表
export function getBorrowInfo(data) {
  return service({
    method: 'post',
    url: '/license/getBorrowInfo',
    data
  })
}

//钉钉端根据证照类型id查询可以借阅的证照
export function getLicenseInfo(data) {
  return service({
    method: 'post',
    url: '/license/getLicenseInfo',
    data
  })
}
//获取证照
export function getClassList(data) {
  return service({
    method: 'post',
    url: '/license/getClassList',
    data
  })
}
//钉钉端借阅申请
export function licenseSave(data) {
  return service({
    method: 'post',
    url: '/license/save',
    data
  })
}

//钉钉端根据借阅列表主键id查询借阅详情
export function getBorrowById(data) {
  return service({
    method: 'post',
    url: '/license/getBorrowById',
    data
  })
}

//钉钉端申请续期
export function applyOverdue(data) {
  return service({
    method: 'post',
    url: '/license/applyOverdue',
    data
  })
}

//获取用户信息
export function getUserInfo(data) {
  return service({
    method: 'post',
    url: '/license/getUserInfo',
    data
  })
}
//查询公章信息名称集合
export function getOfficialSealList(data) {
  return service({
    method: 'post',
    url: '/license/getOfficialSealList',
    data
  })
}
//保管人分页查询可借阅的证照列表
export function getBorrowListByType(params,data) {
  return service({
    method: 'post',
    url: '/license/borrow/getBorrowListByType',
    params:params,
    data
  })
}
//归还修改对应状态
export function returnLicence(params,data) {
  return service({
    method: 'post',
    url: '/license/borrow/returnLicence',
    params:params,
    data
  })
}
