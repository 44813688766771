import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    branchNameList: [],

    licenseList: [],

    // parentCode: '',

    userNo: '',
    userName: '',
    deptNo: '',
    deptName: '',
    code: null,
    jumpFlag: false,
    tel: null,
    sex: null,
    isTabBarDisabled: true,
  },
  mutations: {
    changeIsTabBarDisabled(state, val) {
      state.isTabBarDisabled = val
    },
    setBranchNameList(state, val) {
      state.branchNameList = val
    },
    setLicenseList(state, val) {
      state.licenseList = val
    },
    pushBranchNameList(state, val) {
      state.branchNameList.push(val)
    },
    pushLicenseList(state, val) {
      state.licenseList.push(val)
    },

    // setParentCode(state, val) {
    //   state.parentCode = val
    // },

    setUserNo(state, val) {
      state.userNo = val
    },
    setCode(state, val) {
      state.code = val
    },
    setUserName(state, val) {
      state.userName = val
    },
    setDeptNo(state, val) {
      state.deptNo = val
    },
    setDeptName(state, val) {
      state.deptName = val
    },
    setTel(state, val) {
      state.tel = val
    },
    setSex(state, val) {
      state.sex = val
    },


    delBranchNameList(state) {
      state.branchNameList = []
    },
    delLicenseList(state) {
      state.licenseList = []
    },
    // delParentCode(state) {
    //   state.parentCode = ''
    // },

    changeJumpFlag(state, val) {
      state.jumpFlag = val
    }

  },
  actions: {

  },
  modules: {
  }
})
